import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Envia un metodo get.
   * @param url url del EP
   * @param params params que solicita al EP
   * @param headers setea los headers que recibe
   * @author Martin Batun Tec.
  */
  get<T>(url: string, params?: HttpParams, headers?: HttpHeaders) {
    return this.http.get<T>(url, { params: params, headers: headers });
  }

  /**
   * Envia un metodo post.
   * @param url url del EP
   * @param data el body que sera enviado
   * @author Martin Batun Tec.
  */
  post<T>(url: string, data: any) {
    return this.http.post<T>(url, data);
  }

  /**
   * Envia un metodo patch.
   * @param url url del EP
   * @param data el body que sera enviado
   * @author Martin Batun Tec.
  */
  patch<T>(url: string, data: any) {
    return this.http.patch<T>(url, data);
  }

  /**
   * Envia un metodo put.
   * @param url url del EP
   * @param data el body que sera enviado
   * @author Martin Batun Tec.
  */
  put(url: string, data: any) {
    return this.http.put(url, data);
  }

  /**
   * Envia un metodo delete.
   * @param url url del EP
   * @author Martin Batun Tec.
  */
  delete(url: string) {
    return this.http.delete(url);
  }

}

// intercepta las llamadas a los endpoits para setear el token
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    // req = req.clone({ headers: req.headers.set('Accept-Language', 'es-mx') });
    // req = req.clone({ headers: req.headers.set('Accept', '*/*') });

    const url = `${environment.server}${req.url}`.replace(/([^:]\/)\/+/g, '$1');
    req = req.clone({ url });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          // this.loader.stop(1000);
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        // this.api.msgError(err.error.error.message);
        return throwError(err);
      }),
    );
  }

}
