import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'payment-plans',
    loadChildren: () => import('./pages/payment-plans/payment-plans.module').then(m => m.PaymentPlansModule)
  },
  {
    path: 'check-out',
    loadChildren: () => import('./pages/check-out/check-out.module').then(m => m.CheckOutModule)
  },
  {
    path: 'results',
    loadChildren: () => import('./pages/results/results.module').then(m => m.ResultsModule)
  },
  {
    path: 'insurance',
    loadChildren: () => import('./pages/insurance/insurance.module').then(m => m.InsuranceModule)
  },
  {
    path: 'passenger-data',
    loadChildren: () => import('./pages/passenger-data/passenger-data.module').then(m => m.PassengerDataModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./pages/summary/summary.module').then(m => m.SummaryModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/landing-page-fest/landing-page-fest.module').then(m => m.LandingPageFestModule)
  },
  {
    path: 'estudios',
    loadChildren: () => import('./pages/landing-page-fest/landing-page-fest.module').then(m => m.LandingPageFestModule)
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
